p {
	font-size: @fs; 
	line-height: @lh;
	color: @text-color; 
	margin: @text-margin;
}
h1 {
	font-size: @h1; 
	font-weight: 400; 
	line-height: @lh; 
	color: @text-color; 
	margin: @text-margin;
}
h2 {
	font-family: @ff2;
	.fs(@h2); 
	font-weight: 700; 
	line-height: @lh; 
	text-align: center;
	color: @text-color; 
	margin: @text-margin;
}
h3 {
	font-size: @h3; 
	font-weight: 400; 
	line-height: @lh; 
	color: @text-color; 
	margin: @text-margin;
}
h4 {
	font-size: @h4; 
	font-weight: 400; 
	line-height: @lh; 
	color: @text-color; 
	margin: @text-margin;
}
h5 {
	font-size: @h5; 
	font-weight: 400; 
	line-height: @lh; 
	color: @text-color; 
	margin: @text-margin;
}
h6 {
	font-size: @h6; 
	font-weight: 400; 
	line-height: @lh; 
	color: @text-color; 
	margin: @text-margin;
}
a {
	font-size: @fs; 
	line-height: @lh; 
	color: @text-color; 
	margin: @text-margin;
	&:hover {
		text-decoration: underline;
	}
}

.headline {
	font-size: 4rem;
	font-weight: 700;
	line-height: 1.1;
}