.questions {
	width: 100%;
	padding-top: 10.4rem;
	height: auto;
	text-align: center;
	background: url("@{dir}bg-questions.jpg") no-repeat center;

	@media @mobile {
		padding-bottom: 10rem;
	}
	@media @tablet {
		padding-bottom: 20rem;
	}
	@media @desktop {
		height: 89.5rem;
	}

	h2 {
		max-width: 79rem;
		margin: 0 auto 43rem;

		@media @mobile {
			margin-bottom: 10rem;
		}
		@media @tablet {
			margin-bottom: 15rem;
		}
		@media @desktop {
			margin-bottom: 43rem;
		}
	}

	p {
		position: relative;
		max-width: 31rem;
		font-size: 2rem;
		text-align: center;
		margin: 0 auto 2.8rem;
	}

	.btn {
		position: relative;

		&:before {
			position: absolute;
			display: block;
			content: "";
			top: -4.3rem;
			right: 1.5rem;
			width: 2.4rem;
			height: 3.4rem;
			background: url("@{dir}ar-1.png") no-repeat;
		}
	}
}