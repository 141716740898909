.reviews {
	padding-top: 8.6rem;
	padding-bottom: 8rem;
	background: @grey-light;

	&--2 {
		margin-top: -6rem;
		padding-bottom: 8rem !important;
	}

	@media @mobile {
		padding-top: 3rem;
		padding-bottom: 8rem;
	}
	@media @desktop {
		padding-top: 8.6rem;
		padding-bottom: 12rem;
	}

	h2 {

		@media @mobile {
			margin-bottom: 3rem;
		}
		@media @desktop {
			margin-bottom: 7.5rem;
		}
	}

	&__picture {
		position: relative;
		border-radius: 3px;
		overflow: hidden;

		@media @mobile {
			.col(12,12);
			margin-bottom: 2rem;
		}
		@media @tablet {
			.col(5,12);
		}

		&.reverse {
			@media @tablet {
				.push(7,12);
			}
		}

		img {
			display: block;
			width: auto !important;
			max-width: auto !important;
			margin: 0 auto;

			@media @desktop {
				height: 34rem;
			}
		}
	}
	&__content {
		position: relative;
		margin-bottom: 1.5rem;

		@media @mobile {
			.col(12,12);
		}
		@media @tablet {
			.col(7,12);
			padding-left: 3rem;
		}

		&.reverse {
			@media @tablet {
				.pull(5,12);
				padding-left: 0;
				padding-right: 3rem;
			}
		}

		h3 {
			font-size: 2.8rem;
			margin-bottom: 2.2rem;
		}
		p {
			margin-bottom: 2.2rem;
			span {
				font-weight: 700;
			}
		}
	}

	&__thumb {
		position: relative;
		max-height: 10.5rem;
		border-radius: 3px;
		overflow: hidden !important;
		cursor: pointer;

		@media @mobile {
			display: none;
		}
		@media @tablet {
			display: inline-block;
			.col(2,12,15px,15px,px);
		}

		img {
			position: relative;
			display: block;
			width: 100% !important;
			max-width: auto !important;
			height: auto !important;		
		}
	}
}