.rewards {
	padding-top: 9rem;
	padding-bottom: 7rem;
	background: #f0f0f0;

	@media @mobile {
		padding-top: 2rem;
	}
	@media @tablet {
		padding-top: 3rem;
	}
	@media @desktop {
		padding-top: 9rem;
	}

	h2 {
		margin-bottom: 7.5rem;
	}

	&__item {
		text-align: center;

		@media @mobile {
			.col(12,12);
			margin-bottom: 2rem;
		}
		@media @tablet {
			.col(6,12);
			margin-bottom: 0;
		}
		@media @desktop {
			.col(3,12);
		}

		a {
			position: relative;
			display: inline-block;
			vertical-align: top;
			background: #fff;
			padding: 1.3rem;
			border-radius: 3px;
			margin-bottom: 4rem;
			&:after {
				position: absolute;
				bottom: -1.4rem;
				left: 50%;
				display: block;
				content: "";
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 14px 20px 0 20px;
				border-color: #ffffff transparent transparent transparent;
				margin-left: -2rem;
			}

			img {
				margin-bottom: 0 !important;
			}
		}

		p {
			padding: 0 0.5rem;
			span {
				font-weight: 700;
			}
		}
	}
}