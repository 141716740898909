.header {
	width: 100%;
	background: url("@{dir}bg-top-2.jpg") no-repeat top center;

	@media @mobile {
		height: auto;
	}
	@media @desktop {
		height: 69.5rem;
	}

	&__top {
		.wrap;
		padding-top: 1.5rem;

		@media @mobile {
			margin-bottom: 1rem;
			text-align: center;
		}
		@media @tablet {
			text-align-last: auto;
			margin-bottom: 7.5rem;
		}
		@media @desktop {
			height: 6rem;
		}
	}
	
	&__contacts {
		vertical-align: middle;

		@media @mobile {
			.col(12,12);
		}
		@media @tablet {
			.col(6,12);
		}
	}
	&__phone {
		display: inline-block;
		vertical-align: middle;
		font-size: @fs;
		color: #333333;
		margin-right: 2rem;
	}
	&__btn {
		display: inline-block;
		vertical-align: middle;
		font-size: 1.4rem;
		padding: 1.1rem 2rem;
		border: 1px solid @grey;
		border-radius: 2px;
		margin-bottom: 0;

		&:hover {
			color: #fff;
			background: #5f4243;
		}

		@media @mobile {
			padding: 1rem 1.4rem;
		}
		@media @tablet {
			padding: 1.1rem 1.5rem;
		}
		@media @desktop {
			padding: 1.1rem 2rem;
		}
	}
	&__mail {
		display: inline-block;
		vertical-align: middle;
		text-align: center;
		margin-left: 3rem;

		@media @mobile {
			display: none;
		}
		@media @desktop-xl {
			display: inline-block;
		}

		a {
			font-size: 1.4rem;
			color: @blue;
		}
	}

	&__content {
		max-width: 83rem;
		text-align: center;
		margin: 0 auto;
		padding-bottom: 8rem;

		@media @tablet {
			padding-top: 8rem;
		}
		@media @desktop {
			padding-top: 4rem;
		}

		h1 {
			font-family: @ff2;
			.fs(4.5rem);
			font-weight: 400;
			text-align: center;
			color: #5f4243;
			line-height: 1.3;
			margin-bottom: 2.3rem;
		}

		p {
			font-size: 2.2rem;
			text-align: center;
			color: #5f4243;
			margin-bottom: 3.8rem;
		}
	}
}

.logo {
	width: 100%;
	vertical-align: bottom;
	@media @mobile {
		.col(12,12);
	}
	@media @tablet {
		.col(6,12);
		margin-bottom: 0;
	}

	img {
		max-width: 50%;
		display: inline-block;
		vertical-align: bottom;
	}

	span {
		max-width: 40%;
		display: inline-block;
		vertical-align: bottom;
		text-align: left;
		margin-left: 3rem;

		@media @mobile {
			font-size: 1.2rem;
		}
		@media @tablet {
			font-size: 1.6rem;
		}
	}
}


.main-menu {
	text-align: center;
	margin-bottom: 3.5rem;

	ul {
		display: inline-block;
		vertical-align: top;
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	li {
		display: inline-block;
		vertical-align: top;
		padding: 0 2.4rem;

		@media @mobile {
			padding: 0 0.5rem;
		}
		@media @tablet {
			padding: 0 1.4rem;
		}
		@media @desktop {
			padding: 0 2.4rem;
		}
	}

	a {
		text-decoration: none;

		@media @mobile {
			font-size: 1.5rem;
		}
		@media @tablet {
			font-size: @fss;
		}
		@media @desktop {
			font-size: 1.7rem;
		}

		&:hover {
			text-decoration: underline;
		}
	}
}

.langs {
	//display: inline-block;
	margin: 0;
	padding: 0;
	list-style-type: none;
	padding-top: 0.5rem;

	li {
		display: inline-block;
		vertical-align: top;
		padding: 0 0.4rem;
	}
	img {
		width: 2rem;
	}
}