.steps {
	width: 100%;
	padding-top: 7.2rem;
	background: url("@{dir}bg-steps.jpg") no-repeat center;

	@media @mobile {
		height: auto;
		padding-top: 3.5rem;
		padding-bottom: 3.5rem;
	}
	@media @tablet {
		padding-top: 5.5rem;
	}
	@media @desktop {
		height: 65rem;
		padding-top: 7.2rem;
	}

	h2 {
		@media @mobile {
			margin-bottom: 4rem;
		}
		@media @desktop {
			text-align: left;
			margin-bottom: 8rem;
		}
	}

	&__block {
		@media @mobile {
			.col(12,12);
		}
		@media @desktop {
			.col(9,12);
		}
	}

	&__picture {
		position: relative;
		@media @mobile {
			display: none;
		}
		@media @desktop {
			.col(3,12);
			display: inline-block;
		}

		img {
			@media @desktop-xl {
				max-width: 37.4rem !important;
				position: absolute;
				top: -14rem;
				left: 0;
				height: 78rem !important;
				width: 37.4rem !important;
			}
		}
	}

	&__item {
		

		@media @mobile {
			margin-bottom: 2rem;
		}
		@media @tablet {
			min-height: 14.5rem;
			margin-bottom: 6rem;
		}
		@media @desktop {
			margin-bottom: 7rem;
		}
	}

	&__info {
		@media @mobile {
			.col(12,12);
		}
		@media @tablet {
			.col(6,12);
		}
	}

	&__number {
		position: relative;
		display: inline-block;
		vertical-align: top;
		width: 7rem;
		height: 7rem;
		font-family: @ff2;
		font-size: 3.6rem;
		font-weight: 700;
		color: #fff;
		line-height: 5.8rem;
		text-align: center;
		border-radius: 3px;
		background: @green;
		margin-right: 2.8rem;

		@media @tablet {
			margin-right: 2rem;
		}
		@media @desktop-xl {
			margin-right: 2.8rem;
		}

		&:after {
			display: block;
			position: absolute;
			left: 50%;
			bottom: -16rem;
			content: "";
			width: 1px;
			height: 16rem;
			background: @green;

			@media @mobile {
				bottom: -8rem;
				height: 8rem;
			}
			@media @tablet {
				bottom: -16rem;
				height: 16rem;
			}
		}

		&--2, &--4 {
			&:after {
				@media @tablet {
					display: none;
				}
			}
		}
		&--4 {
			&:after {
				@media @mobile {
					display: none;
				}
			}
		}
	}
	&__content {
		display: inline-block;
		vertical-align: top;
		max-width: 27rem;

		p {
			font-size: 1.8rem;

			@media @mobile {
				font-size: 1.6rem;
			}
			@media @tablet {
				font-size: 1.8rem;
			}
		}
	}
}