.more-info {
	padding-top: 7rem;
	overflow: hidden;

	.row {
		position: relative;
	}

	.vertical-container {
		@media @tablet {
			height: inherit;
		}
	}
	.vertical-inner {
		@media @mobile {
			top: 0 !important;
			transform: translateY(0%);
		}
		@media @desktop {
			top: 50% !important;
  			transform: translateY(-50%);
		}	
	}

	&__text {
		p {
			font-family: @ff2;
			.fs(4.8rem);
			font-weight: 700;

			@media @mobile {
				text-align: center;
			}
			@media @desktop {
				text-align: left;
			}
		}
	}
	&__picture {
		img {
			max-width: 100%;
		}
	}

	&__row {
		&--1 {
			@media @desktop {
				height: 57rem;
			}
			.more-info__text {
				@media @mobile {
					.col(12,12);
				}
				@media @desktop {
					.col(12,12,7);
					margin-top: -5rem;
				}
			}

			.more-info__picture {
				@media @desktop {
					position: absolute;
					top: 0;
					left: -13rem;
				}
			}
		}

		&--2 {
			@media @desktop {
				height: 91.5rem;
			}

			.more-info__text {
				@media @mobile {
					.col(12,12);
				}
				@media @desktop {
					.col(5,12);
				}
			}
			.more-info__picture {
				@media @desktop {
					position: absolute;
					top: 0;
					right: -8rem;
				}
			}
		}

		&--3 {
			@media @mobile {
				padding-bottom: 4rem;
			}
			@media @tablet {
				margin-top: -3rem;
				padding-bottom: 10rem;
			}

			.more-info__text {
				position: relative;
				@media @mobile {
					.col(12,12);
				}
				@media @tablet {
					.col(5,12);
					.push(7,12);
					padding-top: 13rem;
					padding-left: 3rem;
				}
			}
			.more-info__videos {
				position: relative;
				@media @mobile {
					.col(12,12);
				}
				@media @tablet {
					.col(7,12);
					.pull(5,12);
				}
			}
		}
	}
}