.difference {
	position: relative;
	overflow: hidden;

	@media @mobile {
		padding-top: 3rem;
	}
	@media @tablet {
		padding-top: 6rem;
	}

	h2 {
		position: relative;
		z-index: 9;
		@media @mobile {
			margin-bottom: 4rem;
		}
		@media @tablet {
			margin-bottom: 6rem;
		}
		@media @desktop {
			margin-bottom: 8.5rem;
		}
	}

	&__left, &__right {
		position: absolute;
		top: 0;
		width: 50%;
		@media @tablet {
			height: 64.9rem;
		}
	}
	&__left {
		left: 0;
		background: url("@{dir}bg-difference-1.jpg") no-repeat top right;
	}
	&__right {
		left: 50%;
		background: url("@{dir}bg-difference-2.jpg") no-repeat top left;
	}

	&__block {
		position: relative;
		z-index: 9;
		@media @mobile {
			.col(12,12);
		}
		@media @tablet {
			.col(6,12);
		}

		&--1 {
			@media @mobile {
				background: url("@{dir}bg-difference-1.jpg") no-repeat top right;
			}
			@media @tablet {
				background: none;
			}
		}
		&--2 {
			@media @mobile {
				background: url("@{dir}bg-difference-2.jpg") no-repeat top left;
			}
			@media @tablet {
				background: none;
			}
		}
	}

	&__list {
		padding: 0;
		margin: 0;
		list-style-type: none;

		@media @mobile {
			padding: 4rem;
		}
		@media @tablet {
			padding: 0;
		}

		li {
			font-size: 1.9rem;
			margin-bottom: 2.5rem;
			
			@media @mobile {
				font-size: 1.6rem;
			}
			@media @tablet {
				font-size: 1.7rem;
				margin-bottom: 1.5rem;
			}
			@media @desktop {
				font-size: 1.9rem;
				margin-bottom: 2.5rem;
			}
		}

		&--red {
			max-width: 47rem;
			li {
				color: @pink;
			}
		}
		&--green {
			max-width: 37rem;
			li {
				color: #458c8a;
			}
			
			@media @mobile {
				padding-left: 10rem;
				padding-right: 2rem;
			}
			@media @tablet {
				padding-left: 0;
				padding-right: 0;
				margin-left: 5.5rem;
			}
			@media @desktop {
				margin-left: 11.5rem;
			}
			@media @desktop-xl {
				margin-left: 21.5rem;
			}
		}
	}
}