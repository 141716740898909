@dir: 						    	"../images/";
@dir-fonts: 				  		"../fonts/";

@html-size-base: 					10px;

@fs: 						      	1.8rem;
@fss: 						    	1.6rem;
@fsb: 						    	2rem;
@h1: 						      	4.5rem;
@h2: 						      	3.6rem;
@h3: 						      	3rem;
@h4: 						      	2.5rem;
@h5: 						      	2rem;
@h6: 						      	1.9rem;

@lh: 						      	1.2;

@ff1: 								'PT Serif', serif;
@ff2: 								'Playfair Display SC', 'Roboto', serif;

@grey: 								#141413;
@grey-light: 						#f1eee9;
@pink: 								#d5436a;
@green: 							#6fb5b3;
@blue: 								#1995dc;

@text-color: 						#000;
@text-margin: 						0 0 1rem;

@mobile:      ~"only screen and (min-width: 320px)";
@tablet:      ~"only screen and (min-width: 768px)";
@desktop:     ~"only screen and (min-width: 992px)";
@desktop-xl:  ~"only screen and (min-width: 1200px)";

