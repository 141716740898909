.contacts {
	position: relative;
	height: 64.5rem;
	.wrap;

	&__info {
		background: @green;
		height: inherit;
		padding-top: 6.8rem;

		@media @mobile {
			.col(12,12);
		}
		@media @tablet {
			.col(5,12);
		}

		&>div {
			position: relative;
			
			@media @mobile {
				text-align: center;
			}
			@media @tablet {
				text-align: left;
				padding-left: 4rem;
			}
			@media @desktop-xl {
				left: 50%;
				position: absolute;
				margin-left: -58.5rem;
				padding-left: 0;
			}
		}

		h2 {
			color: #fff;
			margin-bottom: 2.8rem;

			@media @mobile {
				text-align: center;
			}
			@media @tablet {
				text-align: left;
			}
		}
		h3 {
			font-size: 2.4rem;
			color: #fff;
			margin-bottom: 2.5rem;
			@media @mobile {
				text-align: center;
			}
			@media @tablet {
				text-align: left;
			}
		}
		p {
			font-size: 1.8rem;
			color: #fff;
			margin-bottom: 2rem;
			@media @mobile {
				text-align: center;
			}
			@media @tablet {
				text-align: left;
			}
		}
	}
	&__map {
		position: relative;
		height: inherit;
		@media @mobile {
			.col(12,12);
			display: none;
		}
		@media @tablet {
			display: inline-block;
			.col(7,12);
		}

		iframe {
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}