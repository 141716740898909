.certificate {
	margin-top: -6.8rem;
	margin-bottom: 6rem;

	.container {
		padding-top: 4.3rem;
		background: @green;
		
		@media @mobile {
			padding-bottom: 5rem;
		}
		@media @desktop {
			padding-bottom: 0;
			max-height: 45.5rem;
		}
	}

	&__info {
		@media @mobile {
			.col(12,12);
		}
		@media @tablet {
			.col(7,12);
		}
		@media @desktop {
			.col(8,12,1);
		}

		h2 {
			color: #fff;
			text-align: left;
		}

		img {

			@media @mobile {
				margin-bottom: 2rem;
			}
			@media @tablet {
				margin-top: -2.5rem;
				margin-bottom: 0;
			}
			@media @desktop {
				margin-top: -5rem;
			}
		}
	}

	&__form {
		@media @mobile {
			.col(12,12);
		}
		@media @tablet {
			.col(5,12);
		}
		@media @desktop {
			.col(4,12,0.5,0.8);
		}

		p {
			font-size: 1.8rem;
			color: #fff;
			margin-bottom: 3rem;

			span {
				display: block;
				font-size: 3rem;
				margin-bottom: 1.3rem;
			}

			em {
				font-style: normal;
				font-weight: 700;
			}
		}

		.input {
			margin-bottom: 6px;
		}
	}
}