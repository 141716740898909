.footer {
	padding: 1.5rem 0;

	&__block {
		@media @mobile {
			.col(12,12);
			text-align: center;
			margin-bottom: 2rem;
		}
		@media @tablet {
			.col(4,12);
			text-align: left;
			margin-bottom: 0;
		}

		&--right {
			p,a {
				display: block;

				@media @mobile {
					text-align: center;
				}
				@media @tablet {
					text-align: right !important;
				}
			}
		}
		&--middle {
			a {
				display: block;
				text-align: center;
				margin: 0 auto !important;
				cursor: pointer;
			}
		}
	}

	p {
		font-size: 1.4rem;
		text-align: center;
		margin-bottom: 0;
	}
	a {
		font-size: 1.4rem;
		color: @blue;
		margin-bottom: 0;

		&:hover {
			text-decoration: none;
		}
	}
}

.remodal.modal-form {
	max-width: 47rem;
	background: @green;
	border-radius: 3px;

	@media @tablet {
		padding: 4.5rem 10rem 6rem;
	}

	h2 {
		font-family: @ff1;
		font-size: 3rem;
		font-weight: 700;
		color: #fff;
		margin-bottom: 0.5rem;
	}
	p {
		font-size: 1.7rem;
		text-align: left;
		color: #fff;
		margin-bottom: 2.2rem;
	}

	.input {
		margin-bottom: 8px;
	}
}
.remodal-close {
	color: #fff !important;
	left: 100% !important;
	margin-left: -3.5rem !important;
}

.remodal.modal-politics {
	max-width: 70%;
	
	h2 {
		margin-bottom: 3rem;
	}
	h3,p {
		text-align: left;
	}
	p {
		font-size: 1.6rem;
		margin-bottom: 1.5rem;
	}
}
.remodal.modal-thanks {
	background: @green;
	border-radius: 3px;
	@media @mobile {
		max-width: 80%;
		padding: 6rem 4rem;
	}
	@media @desktop {
		max-width: 77rem;
		padding: 14rem 10rem;
	}

	h2 {
		font-family: @ff1;
		font-size: 3rem;
		font-weight: 700;
		color: #fff;
		margin-bottom: 2.5rem;
	}
	p {
		font-size: 1.7rem;
		text-align: center;		
		line-height: 1.5;
		color: #fff;
		margin-bottom: 2.2rem;
	}
}

.remodal.modal-image {
	max-width: auto !important;
	padding: 0 !important;
	background: none !important;

	img {
		max-height: 95vh;
	}

	.remodal-close {
		top: -1rem;
	}
}