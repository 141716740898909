.quality {
	padding: 6rem 0 2rem;

	h2 {
		margin-bottom: 5.5rem;
	}

	&__item {
		position: relative;
		margin-bottom: 5.5rem;

		&:before {
			position: absolute;
			top: -2.5rem;
			left: 2.5rem;
			content: "";
			width: 100%;
			height: 100%;
			max-width: 37rem;
			max-height: 37rem;
			border: 1px solid @green;
			border-radius: 3px;
			z-index: 999;

			@media @mobile {
				display: none;
			}
			@media @desktop {
				display: block;
			}
		}

		&:nth-child(2) {
			&:before {
				left: 4rem;
			}
		}

		@media @mobile {
			.col(12,12);
		}
		@media @tablet {
			.col(6,12,15px,15px,px);
		}

		img {
			display: block;
			position: relative;

			@media @mobile {
				max-width: 70%;
			}
			@media @tablet {
				max-width: 70%;
			}
			@media @desktop {
				max-width: 80%;
			}
			@media @desktop-xl {
				max-width: 100%;
			}
		}
	}

	&__info {
		position: relative;
		height: 19rem;
		max-width: 38rem;
		float: right;
		background: @green;
		padding: 1rem 3rem;
		margin-top: -12rem;
		border-radius: 3px;
		z-index: 999;
		
		@media @mobile {
			width: 70%;
		}
		@media @desktop {
			width: 38rem;
		}

		p {
			color: #fff;

			@media @mobile {
				font-size: @fs;
				line-height: 1.3;
			}
			@media @desktop {
				font-size: @fsb;
				line-height: 1.5;
			}
		}
	}
}