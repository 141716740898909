.short {
	padding: 8rem 0;

	@media @mobile {
		padding: 4rem 0;
	}
	@media @tablet {
		padding: 6rem 0;
	}
	@media @desktop {
		padding: 8rem 0;
	}

	&__block {
		position: relative;
		@media @mobile {
			.col(12,12);
		}
		@media @tablet {
			.col(6,12);
		}

		&--1 {
			vertical-align: bottom;
			@media @tablet {
				.push(6,12);
				padding-left: 3rem;
			}
		}
		&--2 {
			vertical-align: bottom;
			@media @tablet {
				.pull(6,12);
			}
		}


		h2 {
			max-width: 37rem;
			position: relative;
			z-index: 9;
			margin-bottom: 2rem;
			

			@media @tablet {
				text-align: left;
				margin-bottom: 5.5rem;
			}
			@media @desktop {
				margin-left: -10rem;
			}
		}

		img {
			display: block;
			margin: 0 auto;
		}
	}
	
	&__list {
		.wrap;

		@media @mobile {
			margin-bottom: 0;
		}
		@media @tablet {
			margin-bottom: -3.2rem;
		}
	}
	&__item {
		vertical-align: top;
		margin-bottom: 3.2rem;

		@media @mobile {
			.col(12,12);
		}
		@media @tablet {
			.col(6,12);
			padding-right: 2.5rem;
		}

		p {
			span {
				display: block;
				font-size: 3.6rem;
				font-weight: 400;
				color: @pink;
			}

			@media @mobile {
				text-align: center;
			}
			@media @tablet {
				text-align: left;
			}
		}
	}
}