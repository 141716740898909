img {
	max-width: 100%;
}

.container {
	margin: 0 auto;
	@media @mobile {
		width: 100%;
		padding: 0 2%;
	}
	@media @desktop-xl {
		width: 117rem;
		padding: 0;
	}
}

.row, .wrap {
	.wrap; 

	& > * {
		vertical-align: top;
	}
}

.vertical-container {
	-webkit-transform-style: preserve-3d;
  	-moz-transform-style: preserve-3d;
  	transform-style: preserve-3d;
}
.vertical-inner {
	position: relative;
  	top: 50% !important;
  	transform: translateY(-50%);
  	z-index: 99;
}

.vertical-bottom {
	position: relative;
  	top: 100%;
  	transform: translateY(-100%);
  	z-index: 99;
}

.btn {
	display: inline-block;
	vertical-align: top;
	width: auto;
	line-height: 1;
	text-align: center;
	text-decoration: none;
	border: none;
	cursor: pointer;
	.transition(all 0.3s ease-in-out);

	&:hover {
		text-decoration: none;
	}

	&-red {
		font-size: 1.7rem;
		color: #fff;
		line-height: 1;
		border-radius: 3px;
		text-transform: uppercase;
		text-shadow: 0px -1px 0px rgba(44, 26, 21, 0.95);
		background-color: rgb(162,30,65);
		background-image: -moz-linear-gradient( 90deg, rgb(162,30,65) 0%, rgb(193,63,97) 100%);
		background-image: -webkit-linear-gradient( 90deg, rgb(162,30,65) 0%, rgb(193,63,97) 100%);
		background-image: -ms-linear-gradient( 90deg, rgb(162,30,65) 0%, rgb(193,63,97) 100%);
		box-shadow: 0px 4px 0px 0px rgba(132, 24, 52, 0.004);
		border-bottom: 4px solid #79162f;

		padding: 2rem 4rem;

		&:hover {
			background: #79162f;
		}
	}
}


.main-form {
	@media @mobile {
		text-align: center;
	}
	@media @tablet {
		text-align: left;
	}

	.btn {
		width: 100%;
		height: 5rem;
		padding-top: 0;
		padding-bottom: 0;
	}
}
.input {
	width: 100%;
	height: 5rem;
	font-family: @ff1;
	font-weight: 400;
	font-size: @fss;
	color: @grey;
	padding-left: 2rem;
	background: #f1eee9;
	outline: none;
	border: none;
	border-radius: 3px;
}

.placeholder(@ff1; @fss; 400; @grey);

.invalid input:required:invalid {
    border: 1px solid @pink;
}

.invalid input:required:valid {
    //
}

input:focus::-webkit-input-placeholder, textarea:focus::-webkit-input-placeholder 		{opacity: 0; transition: opacity 0.3s ease;}
input:focus::-moz-placeholder, textarea:focus::-moz-placeholder          				{opacity: 0; transition: opacity 0.3s ease;}
input:focus:-moz-placeholder, textarea:focus:-moz-placeholder           				{opacity: 0; transition: opacity 0.3s ease;}
input:focus:-ms-input-placeholder, textarea:focus:-ms-input-placeholder      			{opacity: 0; transition: opacity 0.3s ease;}


.owl-controls {
	text-align: center;
}
.owl-nav {
	display: inline-block;
	vertical-align: top;
	padding-top: 3rem;
}
.owl-prev, .owl-next {
	display: inline-block;
	margin: 0 1rem;
	width: 2rem;
	height: 3.7rem;
	background: url("@{dir}prev.png") no-repeat;
}
.owl-next {
	background: url("@{dir}next.png") no-repeat;
}