.skirts {
	padding-top: 5.6rem;
	background: #f1eee9;

	h2 {
		margin-bottom: 4.6rem;
	}

	&__carousel {
		position: relative;
		margin-bottom: 0 !important;
		padding-bottom: 0 !important;

		&:before, &:after {
			height: 92%;
			width: 20rem;
			display: block;
			content: "";
			position: absolute;
			top: 0;
			z-index: 99;

			@media @mobile {
				display: none;
			}
			@media @tablet {
				display: block;
			}
		}
		&:before {
			left: 0;
			background: -moz-linear-gradient(left,  rgba(255,255,255,1) 0%, rgba(125,185,232,0) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(125,185,232,0) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(125,185,232,0) 100%); 
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#007db9e8',GradientType=1 ); 
		}
		&:after {
			right: 0;
			background: -moz-linear-gradient(left,  rgba(125,185,232,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
			background: -webkit-linear-gradient(left,  rgba(125,185,232,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
			background: linear-gradient(to right,  rgba(125,185,232,0) 0%,rgba(255,255,255,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#007db9e8', endColorstr='#ffffff',GradientType=1 ); 
		}

		a {
			display: block;
			max-height: 34rem;
			cursor: pointer;

			@media @mobile {
				max-height: 55rem;
			}
			@media @tablet {
				max-height: 44rem;
			}
			@media @desktop {
				max-height: 34rem;
			}
		}
	}
}

.wide-form {
	background: @green;
	padding-top: 18rem;
	padding-bottom: 5rem;
	margin-top: -13rem;

	p {
		font-size: 2.2rem;
		color: #fff;
		text-align: center;
		margin-bottom: 2.6rem;
	}

	.input-group, .btn-group {
		@media @mobile {
			.col(12,12,1,1);
			margin-bottom: 1rem;
		}
		@media @tablet {
			.col(12,12,3,3);
		}
		@media @desktop {
			.col(3,12,15px,15px,px);
		}
		
	}
}